import React from 'react';
import { graphql } from 'gatsby';
import  StandardContent  from '../components/layouts/StandardContent';

const LayoutPage = ({data}) => {
  return (
    <StandardContent
      data={data}
      padding="8rem 1.5rem 10rem 1.5rem"
    />
  )
}
export default LayoutPage

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        langKey
        pathSlug
      }
      frontmatter {
        disableCreateDate
        disableTagsList
        relatedArticles {
          description
          path
          title
        }
        createDate
        updateDate
        title
        description
        tags {
          value
          label
        }
        jumbotron {
          align
          height
          image {
            childImageSharp {
              fluid(maxWidth:2048) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          overlay {
            backgroundColor
            opacity
          }
          titles {
            sizeMobile
            sizeTablet
            sizeWidescreen
            value
          }
        }
        contents {
          startDate
          endDate
          columnCount
          columns {
            columnOne {
              chunk {
                align
                type
                value
                image {
                  childImageSharp {
                    fluid(maxWidth:2048) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                width
                height
                alt
                useMap {
                  mapName
                  area {
                    shape
                    coords
                    alt
                    link
                  }
                }
                title
                header {
                  cell
                }
                rows {
                  row {
                    cell
                    color
                    textColor
                  }
                }
                note
                entries {
                  title
                  content
                }
                link
                fieldGroups {
                  groupTitle
                  groupTitleSize
                  groupTitleAlign
                  fieldRows {
                    fields {
                      type
                      name
                      label
                      labelPosition
                      placeholder
                      hasIcon
                      icon
                      options {
                        code
                        label
                      }
                      isRequired
                      isRequiredFormat
                      isReadOnly
                      isDisabled
                      value
                    }
                  }
                }
                formType
                submitText
                hasRecaptcha
                hasCopyToClipboard
                hasDecode
                text
                button {
                  align
                  link
                  value
                }
                size
                paddingBottom
                overlay {
                  backgroundColor
                  opacity
                }
                images {
                  image {
                    childImageSharp {
                      fluid(maxWidth:2048) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  title
                  text
                }
                titles {
                  sizeMobile
                  sizeTablet
                  sizeWidescreen
                  value
                }
                isHome
                isTags
                defLatitude
                defLongitude
                defZoom
                latitude
                longitude
                label
              }
            }
            columnTwo {
              chunk {
                align
                type
                value
                image {
                  childImageSharp {
                    fluid(maxWidth:2048) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                width
                height
                alt
                useMap {
                  mapName
                  area {
                    shape
                    coords
                    alt
                    link
                  }
                }
                title
                header {
                  cell
                }
                rows {
                  row {
                    cell
                    color
                    textColor
                  }
                }
                note
                entries {
                  title
                  content
                }
                link
                fieldGroups {
                  groupTitle
                  groupTitleSize
                  groupTitleAlign
                  fieldRows {
                    fields {
                      type
                      name
                      label
                      labelPosition
                      placeholder
                      hasIcon
                      icon
                      options {
                        code
                        label
                      }
                      isRequired
                      isRequiredFormat
                      isReadOnly
                      isDisabled
                      value
                    }
                  }
                }
                formType
                submitText
                hasRecaptcha
                hasCopyToClipboard
                hasDecode
                text
                button {
                  align
                  link
                  value
                }
                size
                paddingBottom
                overlay {
                  backgroundColor
                  opacity
                }
                images {
                  image {
                    childImageSharp {
                      fluid(maxWidth:2048) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  title
                  text
                }
                titles {
                  sizeMobile
                  sizeTablet
                  sizeWidescreen
                  value
                }
                isHome
                isTags
                defLatitude
                defLongitude
                defZoom
                latitude
                longitude
                label
              }
            }
            columnThree {
              chunk {
                align
                type
                value
                image {
                  childImageSharp {
                    fluid(maxWidth:2048) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                width
                height
                alt
                useMap {
                  mapName
                  area {
                    shape
                    coords
                    alt
                    link
                  }
                }
                title
                header {
                  cell
                }
                rows {
                  row {
                    cell
                    color
                    textColor
                  }
                }
                note
                entries {
                  title
                  content
                }
                link
                fieldGroups {
                  groupTitle
                  groupTitleSize
                  groupTitleAlign
                  fieldRows {
                    fields {
                      type
                      name
                      label
                      labelPosition
                      placeholder
                      hasIcon
                      icon
                      options {
                        code
                        label
                      }
                      isRequired
                      isRequiredFormat
                      isReadOnly
                      isDisabled
                      value
                    }
                  }
                }
                formType
                submitText
                hasRecaptcha
                hasCopyToClipboard
                hasDecode
                text
                button {
                  align
                  link
                  value
                }
                size
                paddingBottom
                overlay {
                  backgroundColor
                  opacity
                }
                images {
                  image {
                    childImageSharp {
                      fluid(maxWidth:2048) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  title
                  text
                }
                titles {
                  sizeMobile
                  sizeTablet
                  sizeWidescreen
                  value
                }
                isHome
                isTags
                defLatitude
                defLongitude
                defZoom
                latitude
                longitude
                label
              }
            }
          }
        }
      }
    }
    tagList: markdownRemark(
      frontmatter: { key: { eq: "tags-list" } }
    ) {
      frontmatter {
        defaultLangKey
        tagList {
          tag
          featured
          topLevel
          overrideDefaultLangKey
          translations {
            langKey
            tagDisplay
            shortDesc
            longDesc
          }
        }
      }
    }
    reusable: allMdx(
      filter: {frontmatter: { key: { eq: "reusable" } }}
    ) {
      edges {
        node {
          body
          frontmatter {
            title
          }
        }
      }
    }
  }
`
